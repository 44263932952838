class DB {
  //IndexDB
  static openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("myDatabase", 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("profiles")) {
          db.createObjectStore("profiles", { keyPath: "id" });
        }
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject("Database error: " + event.target.error);
      };
    });
  }

  static async addData(data) {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["profiles"], "readwrite");
      const store = transaction.objectStore("profiles");
      const request = store.put(data); // Use put instead of add

      request.onsuccess = () => {
        resolve("Data added or updated successfully");
      };

      request.onerror = (event) => {
        reject("Put error: " + event.target.errorCode);
      };
    });
  }

  static async getData(id) {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["profiles"], "readonly");
      const store = transaction.objectStore("profiles");
      const request = store.get(id);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject("Get error: " + event.target.error);
      };
    });
  }

  static async deleteData(id) {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["profiles"], "readwrite");
      const store = transaction.objectStore("profiles");
      const request = store.delete(id);

      request.onsuccess = () => {
        resolve("Data deleted successfully");
      };

      request.onerror = (event) => {
        reject("Delete error: " + event.target.error);
      };
    });
  }
  static async clearDB() {
    const db = await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["profiles"], "readwrite");
      const store = transaction.objectStore("profiles");
      const request = store.clear(); // Clear all data in the object store

      request.onsuccess = () => {
        resolve("All data cleared successfully");
      };

      request.onerror = (event) => {
        reject("Clear error: " + event.target.error);
      };
    });
  }
}

export const DBClass = { DB };
